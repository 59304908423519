import { defineStore } from "pinia";

export const useBerechnungStore = defineStore("berechnung", {
  state: () => ({
    berechnung: 1,
    alleTarife: [],
    showPop: null,
    showPopType: "",
    tarif: {},
    showPopApp: false, // Add this line
    dialogWidth: 888,
  }),
  actions: {
     showBerType(type, returnType) {
      let result = {
        title: "Hund",
        color: "#d84315",
        ground: "redground",
        versicherungstyp: "Hunde-Krankenversicherung",
      };
    
      if (type === 2) {
        result.title = "Katze";
        result.color = "#fecf0c";
        result.ground = "katzeground";
        result.versicherungstyp = "Katzen-Krankenversicherung";
      }
    
      // Überprüfe, ob returnType ein gültiger Schlüssel ist
      if (returnType in result) {
        return result[returnType]; // Gib den Wert für den angegebenen Schlüssel zurück
      } else {
        return null; // oder ein anderer Standardwert
      }
    },
    
    
    showBerAlter(alter){
      if (alter == 0){
        return "erstes Lebensjahr";
      }
      if (alter == 1){
        return "1 Jahr";
      }
   
      return alter + " Jahre";
    },
    closePop() {
      this.tarif = false;
      this.showPopType = false;
      this.showPopApp = false;
    },
    setShowPopTarif(data, type) {
      console.log(data);
      if (data) {
        this.tarif = data;
      }
      if (type == "IndexDreiVergleich"){
        this.dialogWidth = 1200;
      }
      this.showPopType = type;
      this.showPopApp = true;
    },
    setAlleTarife(data) {
      this.alleTarife = data;
    },
    setBerechnung(data) {
      this.berechnung = data;
    },
  },
});
