import { defineStore } from "pinia";
import { ref, computed } from "vue"; // Import computed from Vue

export const useCommonDataStore = defineStore("commonData", () => {
  const windowWidth = ref(
    typeof window !== "undefined" ? window.innerWidth : 0
  );

  // Update windowWidth when the window is resized
  if (typeof window !== "undefined") {
    window.addEventListener("resize", () => {
      windowWidth.value = window.innerWidth;
    });
  }
  

  // Define computed properties
  const isMobile = computed(() => windowWidth.value < 780);
  const isIpadAndBelow = computed(() => windowWidth.value < 1450);
  const showMenueRechts = computed(() => windowWidth.value >= 1450);
  const showMenueLeft = computed(() => windowWidth.value >= 1150);
  const isDesktop = computed(() => windowWidth.value >= 840);

  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      console.log(`Element mit der ID ${elementId} wurde nicht gefunden.`);
    }
  };

  const nl2br = (str) => {
    if (!str) return "";
    let modifiedStr = str.replace(/\n/g, "<br>");
    modifiedStr = modifiedStr.replace(/{strong_red}/g, '<b class="text-red">');
    modifiedStr = modifiedStr.replace(/{strong_red_end}/g, "</b>");
    modifiedStr = modifiedStr.replace(
      /{strong_green}/g,
      '<b class="text-green">'
    );
    modifiedStr = modifiedStr.replace(/{strong_green_end}/g, "</b>");
    modifiedStr = modifiedStr.replace(/{strong}/g, "<b>");
    modifiedStr = modifiedStr.replace(/{strong_end}/g, "</b>");
    return modifiedStr;
  };

  const getCurrentYear = () => {
    const now = new Date();
    let year = now.getFullYear();
    const month = now.getMonth(); // Monate sind 0-basiert (0 = Januar, 11 = Dezember)
    if (month === 11) { // Wenn es Dezember ist
      year += 1;
    }
    return year;
  };

  return {
    windowWidth,
    isMobile,
    isIpadAndBelow,
    isDesktop,
    showMenueLeft,
    showMenueRechts,
    scrollToElement,
    nl2br,
    getCurrentYear,
  };
});
