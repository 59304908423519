<template>
  <v-app>
    <LoaderOverlay />

    <div
      v-if="scrollVisible"
      class="pa-2"
      style="background-color:#f2f2f2; height:40px; position:fixed; width:100%; z-index:100;"
    >
      <span
        v-if="!common.isMobile"
        class="mt-0 pa-0 greytext"
        style="font-weight:normal; font-size:18px; height:30px;"
      >
        &nbsp;&nbsp;<b>Das Original</b> | von Tierärzten empfohlen
      </span>
    </div>

    <v-app-bar
      :style="styleSwitch"
      elevation="0"
      style="border-bottom: 6px solid #f2f2f2; background-color:#fff"
    
    >
      <v-btn icon @click="showMenue = !showMenue" style="font-size: 24px">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <div style="width:340px;" class="custom-app-bar-title">
        <h1 class="mt-0">
          <a href="/" class="ggfontbold redtext">
          EisbaumTabelle
            <span v-if="!common.isMobile" style="font-weight:normal;">
              | {{common.getCurrentYear()}} 
            </span>
          </a>
        </h1>
      </div>

      <v-spacer></v-spacer>

      <v-btn
        v-if="common.showMenueLeft"
        :to="'/rechner'"
         size="large"
        outlined
        class="nocaps borderbtn greytext"
      >
        <v-icon class="redtext mr-2">mdi-dog</v-icon>Hunde-Vergleich
      </v-btn>

      <v-btn
        v-if="common.showMenueLeft"
        :to="'/vergleich_katze'"
       size="large"
        outlined
        class="nocaps ml-4 borderbtn greytext"
      >
        <v-icon class="katzetext mr-2">mdi-cat</v-icon> Katzen-Vergleich
      </v-btn>

      <v-btn
        v-if="common.showMenueLeft &&  !userStore.user"
       size="large"
        outlined
        class="nocaps ml-4 borderbtn greytext"
      >
        <v-icon class="greytext mr-2">mdi-apps</v-icon>
        <a href="/#EBW" class="greytext">Realwert-Methode</a>
      </v-btn>

      <v-btn
        v-if="common.showMenueLeft && !userStore.user"
       size="large"
        outlined
        class="nocaps ml-4 borderbtn greytext"
        :to="'/tierarzt'"
      >
        <v-icon class="redtext mr-2">mdi-plus</v-icon> für Tierärzte
      </v-btn>

      <v-btn
        v-if="common.showMenueLeft && userStore.user"
       size="large"
        outlined
        class="nocaps ml-4 borderbtn greytext"
        :to="'/account'"
      >
        <v-icon class="redtext mr-2">mdi-cart</v-icon> EisbaumTabbellen anfordern
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn
        v-if="common.showMenueLeft && !userStore.user"
        @click="showLogin = !showLogin"
         size="large"
        class="nocaps"
        style="background-color: #d84315; color: #fff"
      >
        Tierarzt Login
      </v-btn>

      <v-btn
        v-if="common.showMenueLeft && userStore.user"
        @click="reloadLogout()"
         size="large"
        class="nocaps"
        style="background-color: #d84315; color: #fff"
      >
        Logout
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-if="showMenue"
      v-model="showMenue"
      style="margin-top:36px;"
      mode="floating"
    >
      <MenueLeft :key="componentKey" />
    </v-navigation-drawer>

    <div
      v-if="showMenueStart && common.isDesktop"
      class="pa-4"
      style="position: fixed; top: 120px; left: 0; z-index:1000; width:160px; background-color: #fff;"
    >
      <h3>Rechner starten {{  common.isDesktop }} </h3>
      Starten Sie jetzt einen Online-Vergleich. In nur 1 Minute erhalten Sie Ihr Ergebnis.

      <v-btn
        :to="'/rechner'"
      
        outlined
        class="nocaps mt-4 borderbtn"
      >
        <v-icon class="redtext mr-2">mdi-dog</v-icon> Hund
      </v-btn>
      <v-btn
        :to="'/vergleich_katze'"
      
        outlined
        class="nocaps mt-4 borderbtn"
      >
        <v-icon class="katzetext mr-2">mdi-cat</v-icon> Katze
      </v-btn>
    </div>

    <v-main style="margin-top:20px;">
      <v-container fill-height>
        <v-row align="center" justify="center">
          <v-col cols="12" md="12">
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-dialog v-model="showLogin" max-width="800">
      <v-card class="pa-4">
        <LoginDialog />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="rechnerStore.showPopApp"
      :width="rechnerStore.dialogWidth"
    >
    <v-card elevation="0" class="pa-4">
        <v-row class="mb-4">
          <v-col cols="11"></v-col>
          <v-col cols="1">
            <v-btn
              icon
              @click="rechnerStore.closePop()"
            >
              <v-icon class="redtext">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <!-- Corrected component name -->
        <SpezialPop
          :componentKeyStatus="componentKeyStatus"
          v-if="rechnerStore.showPopType == 'preis_est'"
          :tarif="rechnerStore.tarif"
          type="preis_est"
        />

        <SpezialPop
          :componentKeyStatus="componentKeyStatus"
          v-if="rechnerStore.showPopType == 'rechenweg'"
          :tarif="rechnerStore.tarif"
          tierart="1"
          type="rechenweg"
        />
        <SpezialPop
          :componentKeyStatus="componentKeyStatus"
          v-if="rechnerStore.showPopType == 'rechenwegkatze'"
          :tarif="rechnerStore.tarif"
          tierart="2"
          type="rechenwegkatze"
        />
        <BeschreibungPop
          :componentKeyStatus="componentKeyStatus"
          v-if="rechnerStore.showPopType == 'beschreibungpop'"
          :tarif="rechnerStore.tarif"
      
        />
        <IndexNoVergleich
          :componentKeyStatus="componentKeyStatus"
          v-if="rechnerStore.showPopType == 'IndexNoVergleich'"
        />
        <IndexDreiVergleich
          :componentKeyStatus="componentKeyStatus"
          v-if="rechnerStore.showPopType == 'IndexDreiVergleich'"
          :alleTarife="rechnerStore.alleTarife"
          :berechnung="rechnerStore.berechnung"
          :tarif="rechnerStore.tarif"
        />
    </v-card>
    </v-dialog>

  
  </v-app>
</template>

<script setup>
import { ref,watch, onMounted, onUnmounted ,nextTick} from 'vue';
import MenueLeft from '@/sites/base/MenueLeft.vue';
import LoaderOverlay from '@/sites/base/LoaderOverlay.vue';
import LoginDialog from '@/sites/base/LoginDialog.vue';
import { useUserStore } from '@/stores/user';
import { useCommonDataStore } from '@/stores/common';
import { useBerechnungStore } from '@/stores/rechner';
import BaseCalls from "@/services/BaseCalls";
import { useRouter } from "vue-router";
import { useLoaderStore } from "@/stores/loader";
import SpezialPop from '@/components/rechner/SpezialPop.vue';
import BeschreibungPop from '@/components/rechner/BeschreibungPop.vue';
import IndexNoVergleich from '@/components/rechner/IndexNoVergleich.vue';
import IndexDreiVergleich from '@/components/rechner/IndexDreiVergleich.vue';
const loaderStore = useLoaderStore();
const router = useRouter();

const common = useCommonDataStore();
const userStore = useUserStore();
const componentKeyStatus = ref(0);
const rechnerStore = useBerechnungStore();
const componentKey = ref(0);
const showMenue = ref(false);
const showMenueStart = ref(true);
const showLogin = ref(false);
const styleSwitch = ref('margin-top:40px;');
const scrollVisible = ref(true);
const scrollPosition = ref(0);
function setLoading(loading) {
  loaderStore.setLoader(loading);
}

function makeSmall() {
  const currentScrollPosition =
    window.pageYOffset || document.documentElement.scrollTop;
  if (currentScrollPosition > scrollPosition.value) {
    styleSwitch.value = 'margin-top:0px;';
    scrollVisible.value = false;
  } else {
    styleSwitch.value = 'margin-top:40px;';
    scrollVisible.value = true;
  }
  scrollPosition.value = currentScrollPosition;
}

async function reloadLogout() {
       setLoading(true);
       let select = {
        action: "startlogout"
       };
         
    const resp = await BaseCalls.postUser(select); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.success) {
       setLoading(false);
       componentKey.value += 1;
       userStore.setUser(false);
       router.push("/logout");
      }
        
      }
async function checkLogin() {
  let select = {
    action: "checklogin"
  };
  const resp = await BaseCalls.postUser(select); // Korrigiert, um .value zu verwenden
    if (resp.data.user) {
      userStore.setUser(resp.data.user);
    }
       
    }

  async function getGuestCookie() {
      let select = {
        action: "getguestcookie"
      };
      const resp = await BaseCalls.postGuest(select); // Korrigiert, um .value zu verwenden
          if (resp.data.check > 0) {
              console.log("check guest insert", resp.data.check);
          } else {
            trackCheckGuest();
          }
          componentKey.value += 1;
    
      }
      async function trackCheckGuest() {
        let select = {
          action: "trackCheckGuest"
        };
        const resp = await BaseCalls.postGuest(select); // Korrigiert, um .value zu verwenden
        console.log("trackCheckGuest insert", resp.data.check);
      }

      async function getLastBerechnungen() {
        let select = {
          action: "getLastBerechnungen"
        };
        const resp = await BaseCalls.postRechner(select); // Korrigiert, um .value zu verwenden
        if (resp.data.empfehlung) {
          rechnerStore.setBerechnung(resp.data.empfehlung);
        }
      }
      
// Define the checkLinkReferrer function
async function checkLinkReferrer() {
  const referrer = document.referrer;
  const isGoogleLink = referrer.includes('.google');
  const isOurLink = referrer.includes('.eisbaum');
  const isLocalLink = referrer.includes('local');
  const isOurLinkLocal = referrer.includes('.etb.de');

  if (isGoogleLink) {
    console.error('Track GG:');
    const selectVisit = {
      action: 'insertGoogleVisit',
      location: window.location.href,
    };
    await BaseCalls.postGuest(selectVisit);
  }

  if (!isGoogleLink && !isOurLink && !isLocalLink && !isOurLinkLocal) {
    const selectReferrer = {
      action: 'trackReferrerLinks',
      location: referrer,
    };
    await BaseCalls.postGuest(selectReferrer);
  }
}

onMounted(() => {
  checkLinkReferrer();
  checkLogin();
  window.addEventListener('scroll', makeSmall);
  nextTick(() => {
    setTimeout(getGuestCookie, 1000);
  });
  getLastBerechnungen();
});


onUnmounted(() => {
  window.removeEventListener('scroll', makeSmall);
});

watch(
  [() => rechnerStore.berechnung, () => userStore.user, () => common.showMenueLeft],
  ([berechnung, user, showMenueLeft]) => {

    if ((berechnung || user) && showMenueLeft) {
      showMenue.value = true;
      showMenueStart.value = false;
    }
    if (!berechnung &&  !user && showMenueLeft) {
      showMenueStart.value = true;
    }
  }
);
</script>



<style>
a {
  color: #1976d2 !important;
  cursor: pointer;
}
li {
  margin-left: 14px;
}
ul {
  margin-left: 10px;
}

.scroll-content {
  max-height: 500px;
  overflow-y: auto;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
}

.scroll-content.hide {
  max-height: 0;
  opacity: 0;
  transition: max-height 0.3s ease-in, opacity 0.3s ease-in;
}

.nocaps {
  text-transform: none !important;
}

.smallcaps {
  text-transform: none !important;
  letter-spacing: normal !important;
  font-size: 20px;
}

.smalltext {
  font-size: 12px;
}

.greytext {
  color: #222222 !important;
}

.lightgreytext {
  color: #f2f2f2 !important;
}

.textnormal {
  font-size: 16px;
}

.redtext {
  color: #d84315 !important;
}

.katzetext {
  color: #fecf0c !important;
}

.ggfont {
  font-family: 'Roboto', sans-serif;
}

.ggfontbold {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
}

.ggfontitalic {
  font-family: 'Roboto', sans-serif;
  font-style: italic;
}

.ggfonthigh {
  font-family: 'Roboto', sans-serif;
  color: #d84315 !important;
}

.title_strong {
  font-size: 20px;
  color: #555555 !important;
  font-family: 'Roboto', sans-serif;
  font-style: italic;
}

.headerlink {
  font-family: 'Roboto', sans-serif;
  text-decoration: none;
  color: #555555 !important;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.redground {
  background-color: #d84315;
}



.borderbtn {
  border: 2px solid #cccccc;
}

.katzeground {
  background-color: #fecf0c;
}

.greyground {
  background-color: #f2f2f2;
}

a:link {
  text-decoration: none;
}

.divider {
  border-bottom: 1px solid #c5c5c5 !important;
  margin-top: 12px;
  margin-bottom: 12px;
}

.dividersmall {
  border-bottom: 1px solid #c5c5c5 !important;
  margin-top: 6px;
  margin-bottom: 6px;
}

b {
  color: #555555;
}

h3 {
  font-size: 18px;
  color: #555555;
}

h2 {
  font-size: 22px;
  color: #555555;
}
</style>